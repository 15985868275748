import React, { useState, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';

import { faAngleDown, faAngleUp, faEdit, faEllipsisH, faEye, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import { Nav, Card, Button, Table, Form, Alert, FormCheck, Dropdown, Pagination, ButtonGroup, Col, Row, Modal } from '@themesberg/react-bootstrap';

import { Link, useHistory } from 'react-router-dom';

import Preloader from "../components/Preloader";

import Loader from "../components/Loader";



import { Routes } from "../routes";

import transactions from "../data/transactions";

import axios from 'axios';

import Swal from 'sweetalert2';



const ValueChange = ({ value, suffix }) => {

	const valueIcon = value < 0 ? faAngleDown : faAngleUp;

	const valueTxtColor = value < 0 ? "text-danger" : "text-success";



	return (

		value ? <span className={valueTxtColor}>

			<FontAwesomeIcon icon={valueIcon} />

			<span className="fw-bold ms-1">

				{Math.abs(value)}{suffix}

			</span>

		</span> : "--"

	);

};







export const UserTable = (searchKey) => {



	const [showDefault, setShowDefault] = useState(false);

	const handleClose = () => {
		setShowDefault(false);
	};




	const [data, setData] = useState([]);

	const [currentPage, setCurrentPage] = useState(1);

	const [startPage, setStartPage] = useState(1);
	const [error, setError] = useState(null);

	const [endPage, setEndPage] = useState(1);

	const [totalRecord, setTotalRecord] = useState(1);

	const [perPage, setPerPage] = useState(1);

	const [totalPages, setTotalPages] = useState(1);

	const [search, setSearch] = useState("");

	const [loading, setLoading] = useState(true);
	const [pageLoad, setPageLoad] = useState(true);


	const [currentSort, setCurrentSort] = useState("id");
	
	const [currentDirection, setCurrentDirection] = useState("desc");

	const [userData, setUserData] = useState([]);

	const [showdetailDefault, setShowDetailDefault] = useState(false);

	const handleDetailsClose = () => setShowDetailDefault(false);

	const [deactiveReason, setDeactiveReason] = useState([]);

	const storedUser = localStorage.getItem('user');

	const userObject = JSON.parse(storedUser);

	const accessToken = userObject.access_token;

	const user_id = userObject.user_id;

	const role_id = userObject.role_id;
	const role_idd = userObject.role_id;

	const setup_admin_permission = userObject.setup_admin_permission;

	const invite_user_permission = userObject.invite_user_permission;

	const suspend_user_permission = userObject.suspend_user_permission;

	const delete_user_permission = userObject.delete_user_permission;
	const [countries, setCountry] = useState(false);

	{/*Sorting*/ }

	const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });

	const btnWidth = {

		width: '125px'

	};





	const handleSort = (key) => {

		let direction = 'asc';

		if (sortConfig.key === key && sortConfig.direction === 'asc') {

			direction = 'desc';

		}

		setSortConfig({ key, direction });
		
		setCurrentSort(key);
		
		setCurrentDirection(direction);



		const apiBaseURL = process.env.REACT_APP_API_BASE_URL + '/users';



		const formData = new FormData();

		formData.append('page', 1);

		formData.append('search', search);

		formData.append('sortBy', key);

		formData.append('orderBy', direction);



		if (role_id != 1) {

			formData.append('user_id', user_id);

		} else {

			formData.append('user_id', "");

		}



		setLoading(true);



		axios.post(apiBaseURL, formData, {

			headers: {

				Authorization: `Bearer ${accessToken}` // Include the access token in the Authorization header

			}

		})

			.then(({ data }) => {

				if (data.status === "success") {

					setData(data.data.data);

					setCurrentPage(data.data.current_page);

					setTotalRecord(data.data.total);

					setPerPage(data.data.per_page);

					setStartPage(data.data.per_page);

					setEndPage(data.data.last_page);



					var totalPages = Math.ceil(data.data.total / data.data.per_page);

					setTotalPages(totalPages);



				}

				setLoading(false);

			}).catch(error => {

				setLoading(false);

			});



	};





	const getSortIcon = (columnKey) => {

		if (sortConfig.key === columnKey) {

			return sortConfig.direction === 'asc' ? <FontAwesomeIcon icon={faSortUp} /> : <FontAwesomeIcon icon={faSortDown} />;

		}

		return <FontAwesomeIcon icon={faSort} />;

	};
	function truncateString(str, maxLength) {
		if (str.length > maxLength) {
			return str.substr(0, maxLength) + "...";
		}
		return str;
	}



	{/*End Sorting*/ }
	useEffect(() => {

		setLoading(true);

		const apiBaseURL1 = process.env.REACT_APP_API_BASE_URL + '/country_list';



		axios.get(apiBaseURL1, {})

			.then(({ data }) => {

				if (data.status === "success") {

					setCountry(data.data);

					setLoading(false);

					setPageLoad(false);

				}

			}).catch(error => {

			});
			
			
			
			const apiBaseURLReason = process.env.REACT_APP_API_BASE_URL + '/deactive_reason';
			axios.get(apiBaseURLReason, {}).then(({ data }) => {
				if (data.status === "success") {
					setDeactiveReason(data.data);
				}
			}).catch(error => {

			});
			

	}, []);





	const fetchData = async page => {



		const apiBaseURL = process.env.REACT_APP_API_BASE_URL + '/users';



		const formData = new FormData();

		if (typeof page !== 'undefined') {
		  formData.append('page', page);
		} else {
		  formData.append('page', currentPage);
		}

		formData.append('search', search);
		
		formData.append('sortBy', currentSort);
		
		formData.append('orderBy', currentDirection);
		

		if (role_id != 1) {

			formData.append('user_id', user_id);

		} else {

			formData.append('user_id', "");

		}



		setLoading(true);



		axios.post(apiBaseURL, formData, {

			headers: {

				Authorization: `Bearer ${accessToken}` // Include the access token in the Authorization header

			}

		})

			.then(({ data }) => {

				if (data.status === "success") {

					setData(data.data.data);

					setCurrentPage(data.data.current_page);

					setTotalRecord(data.data.total);

					setPerPage(data.data.per_page);

					setStartPage(data.data.per_page);

					setEndPage(data.data.last_page);



					var totalPages = Math.ceil(data.data.total / data.data.per_page);

					setTotalPages(totalPages);



				}

				setLoading(false);

			}).catch(error => {

				setLoading(false);

			});



	};





	useEffect(() => {

		setSearch(searchKey?.data);

	}, [searchKey?.data]);

	useEffect(() => {
		fetchData(currentPage);
	}, [search]);





	const handlePageChange = page => {

		setCurrentPage(page);

	};



	const onPageChange = (pageNo) => {

		setCurrentPage(pageNo);

		fetchData(pageNo);

	};



	if (loading) {

		//return <div>Loading...</div>;

	}



	const items = [];

	for (let i = 0; i < totalPages; i++) {

		items.push(i + 1);

	}






	const totalUsers = data.length;



	const srStart = (currentPage - 1) * perPage;



	const modifiedData = data.map((t, index) => ({

		...t,

		serialNumber: srStart + index + 1 // Assuming the serial number starts from 1

	}));



	const prevPage = currentPage > 1 ? currentPage - 1 : 1;

	const nextPage = currentPage < totalPages ? currentPage + 1 : totalPages;




	// Create Users
	const [formData1, setFormData] = useState({
		first_name: '',
		last_name: '',
		email: '',
		phone_number: '',
		buttonName: '',
		address_1: '',
		address_2: '',
		city: '',
		post_code: '',
		country: 'UNITED KINGDOM',
		role_id: 0,
		setupAdmin: '0',
		inviteUser: '0',
		assignUnavailableMonth: '0',
		receiveMonthApproval: '0',
		suspendUser: '0',
		deleteUser: '0',
		id: ''
	});



	const [errors, setErrors] = useState({
		first_name: '',
		last_name: '',
		email: '',
		phone_number: '',
		address_1: '',
		address_2: '',
		city: '',
		post_code: '',
		country: '',
	});

	const editDetails = (id) => {
		console.log("formData1", formData1);
		const formData = new FormData();
		formData.append('id', id);

		const apiBaseURL = process.env.REACT_APP_API_BASE_URL + '/users/' + id;

		axios.post(apiBaseURL, formData, {
			headers: {
				Authorization: `Bearer ${accessToken}` // Include the access token in the Authorization header
			}
		}).then(({ data }) => {

			if (data.status === "success") {
				//setFormData(data.data.data);
				setShowDefault(true);

				//console.log("data.data.data",data.data.data[0]);

				const datas = {
					first_name: data?.data?.data?.[0]?.first_name,
					last_name: data?.data?.data?.[0]?.last_name,
					email: data?.data?.data?.[0]?.email,
					phone_number: "+" + data?.data?.data?.[0]?.phone_number,
					address_1: data?.data?.data?.[0]?.address_1,
					address_2: data?.data?.data?.[0]?.address_2,
					city: data?.data?.data?.[0]?.city,
					post_code: data?.data?.data?.[0]?.post_code,
					country: "UNITED KINGDOM",
					role_id: data?.data?.data?.[0]?.role_id,
					setupAdmin: data?.data?.data?.[0]?.setup_admin_permission,
					inviteUser: data?.data?.data?.[0]?.invite_user_permission,
					assignUnavailableMonth: data?.data?.data?.[0]?.assign_month_permission,
					receiveMonthApproval: data?.data?.data?.[0]?.approval_request_permission,
					suspendUser: data?.data?.data?.[0]?.suspend_user_permission,
					deleteUser: data?.data?.data?.[0]?.delete_user_permission,
					id: id
				};

				setFormData(datas);


			}

		}).catch(error => {

		});
	};



	const [invalid, setInvalid] = useState(false);
	const [mobileNumber, setmobileNumber] = useState("+44");
	const handleInputChange = (e) => {
		const { name, value } = e.target;
		if (name == 'phone_number' && value.length <= 15) {
			setFormData({
				...formData1,
				[name]: value,
			});
			setmobileNumber(value);
		} else {
			setFormData({
				...formData1,
				[name]: value,
			});
		}
	};
	const handleCheckboxChange = (event) => {
		let value1 = "0";
		const { name, value } = event.target;
		let checkedValue = event.target.checked;
		if (checkedValue === true) {
			value1 = "1";
		} else {
			value1 = "0";
		}
		setFormData({
			...formData1,
			[name]: value1,
		});
	};
	const handleSubmit = (e) => {
		e.preventDefault();
		// Get the name of the button clicked
		const buttonName = e.nativeEvent.submitter.name;
		const formsbutton = formData1['buttonName'] = buttonName;
		//console.log(formData);
		// Validate email and password
		let valid = true;
		const newErrors = {
			first_name: '',
			last_name: '',
			email: '',
			phone_number: '',
			address_1: '',
			address_2: '',
			city: '',
			post_code: '',
			country: '',
		};
		if (!formData1.email || !formData1.email.match(/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/)) {
			newErrors.email = 'Please enter a valid email address';
			valid = false;
		}
		if (formData1.email === "") {
			newErrors.email = 'Email field is required';
			valid = false;
		}
		if (formData1.first_name === "") {
			newErrors.first_name = 'First Name field is required';
			valid = false;
		}
		if (formData1.last_name === "") {
			newErrors.last_name = 'Last Name field is required';
			valid = false;
		}
		if (formData1.phone_number === "") {
			newErrors.phone_number = 'Phone Number field is required';
			valid = false;
		} else {
			const ukMobileNumberRegex = /^(?:0|\+44)(?:\d\s?){9,10}$/;
			if (!ukMobileNumberRegex.test(formData1.phone_number)) {
				newErrors.phone_number = 'Phone Number must be a valid number. (UK eg.+447975556677)';
				valid = false;
			}
		}
		if (formData1.post_code === "" || formData1.post_code === null) {
			//newErrors.post_code = 'Post Code field is required';
			//valid = false;
		} else {
			const ukPostcodeRegex = /^[a-zA-Z0-9\s]+$/;
			// const ukPostcodeRegex = /^[A-Z]\d{4}[A-Z]{2}$/;
			if (!ukPostcodeRegex.test(formData1.post_code)) {
				newErrors.post_code = 'Please enter a valid post code. (e.g. D1234GH)';
				valid = false;
			}
		}
		if (formData1.country === "" || formData1.country === null) {
			//newErrors.country = 'Country field is required';
			//valid = false;
		}
		setErrors(newErrors);
		if (valid) {
			console.log("formData1", formData1);

			setLoading(true);
			const apiBaseURL = process.env.REACT_APP_API_BASE_URL + '/update_user';
			axios.post(apiBaseURL, formData1, {
				headers: {
					Authorization: `Bearer ${accessToken}`
				}
			})
				.then(({ data }) => {
					if (data.status === "success") {
						setShowDefault(false);
						setLoading(false);
						//window.location.reload();
						setShowDefault(false);
						fetchData();
					}
					if (data.status === "error") {
						setError('The email is already in use');
						setLoading(false);
					}
				}).catch(error => {
					setLoading(false);
				});
		}
	};
	
	
	const updateStatus = (user_id, is_active) => {
	  let titles = "";
	  let sub_details = "";

	  if (is_active === "1") {
		titles = "Deactivate User";
		sub_details = "Are you sure, Do you want to deactivate this user?";
	  } else {
		titles = "Activate User";
		sub_details = "Are you sure, Do you want to activate this user?";
	  }

	  // Function to handle API call with or without reason
	  const submitStatusChange = (reason_id = null) => {
		setLoading(true);  
		const apiBaseURL = `${process.env.REACT_APP_API_BASE_URL}/users/status/${user_id}`;
		axios.post(apiBaseURL, { reason_id, user_id }, {
		  headers: {
			Authorization: `Bearer ${accessToken}`
		  }
		}).then(({ data }) => {
		  if (data.status === "success") {
			setShowDefault(false);
			setLoading(false);
			fetchData(currentPage);
		  }
		}).catch(error => {
		  console.error("Error updating status:", error);
		});
	  };

	  // If deactivating user, show reason selection modal
	  if (is_active === "1") {
		const reasonOptions = deactiveReason.map((reason) => `
		  <option value="${reason.id}">${reason.reason}</option>
		`).join("");

		Swal.fire({
		  title: titles,
		  text: sub_details,
		  icon: 'warning',
		  showCancelButton: true,
		  confirmButtonText: 'Submit',
		  cancelButtonText: 'Cancel',
		  confirmButtonColor: '#262B40',
		  html: `
			<select id="deactivationReason" class="swal2-input">
			  <option value="" selected disabled>Select a reason</option>
			  ${reasonOptions}
			</select>
		  `,
		  preConfirm: () => {
			const selectedReason = Swal.getPopup().querySelector('#deactivationReason').value;
			if (!selectedReason) {
			  Swal.showValidationMessage('Please select a reason');
			  return false;
			}
			return selectedReason;
		  }
		}).then((result) => {
		  if (result.isConfirmed) {
			const selectedReason = result.value;
			submitStatusChange(selectedReason); // Pass the selected reason ID
		  }
		});
	  } else {
		// If activating user, proceed without reason
		Swal.fire({
		  title: titles,
		  text: sub_details,
		  icon: 'warning',
		  showCancelButton: true,
		  confirmButtonText: 'Submit',
		  cancelButtonText: 'Cancel',
		  confirmButtonColor: '#262B40',
		}).then((result) => {
		  if (result.isConfirmed) {
			submitStatusChange(null); // No reason needed, set reason_id to null
		  }
		});
	  }
	};


	const TableRow = (props) => {

		const { id, name, email, phone_number, serialNumber, role_id, created_at, is_saving_commitment, is_invited, is_active } = props;

		const storedUser = localStorage.getItem('user');

		const userObject = JSON.parse(storedUser);

		const accessToken = userObject.access_token;

		const history = useHistory();

		// Convert created_at to a Date object
		const timezone = 'Europe/London'; // Replace with your desired timezone

		const createdAtDate = new Date(created_at);
		const options = { timeZone: timezone };

		// Format created_at as DD-MM-YYYY HH:mm:ss in the specified timezone
		const formattedCreatedAt = createdAtDate.toLocaleString('en-US', options);




		const handleDelete = () => {

			const formData = new FormData();

			formData.append('id', id);

			// Show a SweetAlert confirmation dialog

			if (is_saving_commitment == '1') {

				Swal.fire({

					icon: 'error',

					title: 'Oops...',

					text: 'This user have a already a saving commitment',

				})

			} else {

				Swal.fire({

					title: 'Delete Item',

					text: 'Are you sure you want to delete this User?',

					icon: 'warning',

					showCancelButton: true,

					confirmButtonText: 'Delete',

					cancelButtonText: 'Cancel',

					confirmButtonColor: '#262B40',

				}).then((result) => {

					if (result.isConfirmed) {

						const apiBaseURL = process.env.REACT_APP_API_BASE_URL + '/users/delete';

						axios.post(apiBaseURL, formData, {

							headers: {

								Authorization: `Bearer ${accessToken}` // Include the access token in the Authorization header

							}

						})

							.then(({ data }) => {

								if (data.status === "success") {

									history.push('/users');

									setShowDefault(false);

									window.location.reload();

								}

								//setLoading(false);

							}).catch(error => {

								//setLoading(false);

							});

					}

				});

			}

		};



		const makeAdmin = () => {

			const formData = new FormData();

			formData.append('id', id);

			// Show a SweetAlert confirmation dialog

			Swal.fire({

				title: 'Make Admin',

				text: 'Are you sure you want to make this user as admin?',

				icon: 'warning',

				showCancelButton: true,

				confirmButtonText: 'Submit',

				cancelButtonText: 'Cancel',

				confirmButtonColor: '#262B40',

			}).then((result) => {

				if (result.isConfirmed) {

					const apiBaseURL = process.env.REACT_APP_API_BASE_URL + '/users/makeadmin';

					axios.post(apiBaseURL, formData, {

						headers: {

							Authorization: `Bearer ${accessToken}` // Include the access token in the Authorization header

						}

					})

						.then(({ data }) => {

							if (data.status === "success") {

								history.push('/users');

								setShowDefault(false);

								window.location.reload();

							}

							//setLoading(false);

						}).catch(error => {

							//setLoading(false);

						});

				}

			});

		};



		const handleInvite = () => {

			const formData = new FormData();

			formData.append('id', id);

			Swal.fire({

				title: 'Invite User',

				text: 'Are you sure you want to invite this User?',

				icon: 'warning',

				showCancelButton: true,

				confirmButtonText: 'Invite',

				cancelButtonText: 'Cancel',

				confirmButtonColor: '#262B40',

			}).then((result) => {

				if (result.isConfirmed) {

					const apiBaseURL = process.env.REACT_APP_API_BASE_URL + '/users/updateinvite';

					axios.post(apiBaseURL, formData, {

						headers: {

							Authorization: `Bearer ${accessToken}` // Include the access token in the Authorization header

						}

					})

						.then(({ data }) => {

							if (data.status === "success") {

								history.push('/users');

								setShowDefault(false);

								window.location.reload();

							}

							//setLoading(false);

						}).catch(error => {

							//setLoading(false);

						});

				}

			});

		};





		const viewDetails = () => {

			const formData = new FormData();

			formData.append('id', id);



			const apiBaseURL = process.env.REACT_APP_API_BASE_URL + '/users/' + id;

			axios.post(apiBaseURL, formData, {

				headers: {

					Authorization: `Bearer ${accessToken}` // Include the access token in the Authorization header

				}

			})

				.then(({ data }) => {

					if (data.status === "success") {

						setUserData(data.data.data[0]);

						setShowDetailDefault(true);

					}

				}).catch(error => {

				});

		};



		return (

			<tr>

				<td>
					<span className="fw-normal">
						{`M-${serialNumber.toString().padStart(6, '0')}`}
					</span>
				</td>

				<td>
					<span className="fw-normal">
						{truncateString(name, 25)}
					</span>
				</td>

				<td>
					<span className="fw-normal">
						{email}
					</span>
				</td>

				<td>
					<span className="fw-normal">
						{phone_number}
					</span>
				</td>

				{role_idd !== 0 &&
					<td>
						<span className="fw-normal">
							{role_id !== 1 ? 'Savers' : 'Admin'}
						</span>
					</td>
				}

				<td>
					<span className="fw-normal">
						{formattedCreatedAt}
					</span>
				</td>
				
				<td>
					<label className="switch">
						<input 
							type="checkbox" 
							name="email_switch" 
							checked={is_active == "1"} // Check if is_show is 1
							onChange={() => updateStatus(id, is_active)}
						/>
						<span className="slider round"></span>
					</label>
				</td>

				<td>
					<Dropdown as={ButtonGroup} align="start" drop="left">
						<Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
							<span className="icon icon-sm">
								<FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
							</span>

						</Dropdown.Toggle>

						<Dropdown.Menu >
							{role_idd !== 0 && setup_admin_permission == "1" && role_id != 1 &&
								<Dropdown.Item onClick={makeAdmin}>
									<FontAwesomeIcon icon={faEye} className="me-2" /> Make Admin
								</Dropdown.Item>
							}

							<Dropdown.Item onClick={viewDetails}>
								<FontAwesomeIcon icon={faEye} className="me-2" /> View Details
							</Dropdown.Item>


							<Dropdown.Item onClick={() => editDetails(id)}>
								<FontAwesomeIcon icon={faEdit} className="me-2" /> Edit Details
							</Dropdown.Item>


							{delete_user_permission == "1" && (
								<Dropdown.Item className="text-primary" onClick={handleDelete}>
									<FontAwesomeIcon icon={faTrashAlt} className="me-2" /> Remove
								</Dropdown.Item>
							)}

							{is_invited == "0" && invite_user_permission == "1" && (
								<Dropdown.Item className="text-primary" onClick={handleInvite}>
									<FontAwesomeIcon icon={faEllipsisH} className="me-2" /> Invite
								</Dropdown.Item>
							)}
						</Dropdown.Menu>
					</Dropdown>
				</td>

			</tr>

		);

	};

	return (

		<>

			{loading && <Preloader show={true} />}

			<Card border="light" className="table-wrapper table-responsive shadow-sm">

				<Card.Body className="pt-0">

					<Table hover className="user-table align-items-center">

						<thead>

							<tr>

								<th className="border-bottom notsort" 
								onClick={() => handleSort('id')}> {role_id !== 1 ? 'Invitation ID' : 'Invitation ID'} {getSortIcon('id')}
								</th>
						
								<th className="border-bottom" onClick={() => handleSort('name')}>Name {getSortIcon('name')}</th>

								<th className="border-bottom" onClick={() => handleSort('email')}>Email {getSortIcon('email')}</th>

								<th className="border-bottom" onClick={() => handleSort('phone_number')}>Phone Number {getSortIcon('phone_number')}</th>

								{role_idd !== 0 &&
									<th className="border-bottom" onClick={() => handleSort('role_id')}>Role {getSortIcon('role_id')}</th>
								}
								<th className="border-bottom" onClick={() => handleSort('created_at')}>Created On {getSortIcon('created_at')}</th>

								<th className="border-bottom">Status</th>

								<th className="border-bottom">Action</th>

							</tr>

						</thead>

						<tbody>

							{modifiedData.map(t => <TableRow key={`data-${t.id}`} {...t} />)}

						</tbody>

					</Table>

					<Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">

						<Nav>

							<Pagination className="mb-2 mb-lg-0">

								<Pagination.Prev onClick={() => onPageChange(prevPage)}>

									Previous

								</Pagination.Prev>



								{items.map((item, index) => (

									<Pagination.Item active={item === currentPage} onClick={() => onPageChange(item)}>{item}</Pagination.Item>

								))}



								<Pagination.Next onClick={() => onPageChange(nextPage)}>

									Next

								</Pagination.Next>

							</Pagination>

						</Nav>

						<small className="fw-bold">

							Showing <b>{totalUsers}</b> out of <b>{totalRecord}</b> entries

						</small>

					</Card.Footer>

				</Card.Body>





				<React.Fragment>

					<Modal as={Modal.Dialog} centered show={showdetailDefault} onHide={handleDetailsClose}>

						<Modal.Header>

							<Modal.Title className="h6">User Details</Modal.Title>

							<Button variant="close" aria-label="Close" onClick={handleDetailsClose} />

						</Modal.Header>

						<Modal.Body>

							<Row>

								<Col xs={12} xl={12}>

									<Card border="light" className="bg-white shadow-sm mb-4">

										<Card.Body>

											<h5 className="mb-4">User Details</h5>

											<Row>

												<Col md={12}>

													<Col md={7} style={{ float: 'right' }}>

														<p>{userData.first_name ?? ""}</p>

													</Col>

													<Col md={5}>

														<b>First Name :</b>

													</Col>

												</Col>
												<Col md={12}>

													<Col md={7} style={{ float: 'right' }}>

														<p>{userData.last_name ?? ""}</p>

													</Col>

													<Col md={5}>

														<b>Last Name :</b>

													</Col>

												</Col>

												<Col md={12}>

													<Col md={7} style={{ float: 'right' }}>

														<p>{userData.email ?? ""}</p>

													</Col>

													<Col md={5}>

														<b>Email :</b>

													</Col>

												</Col>

												<Col md={12}>

													<Col md={7} style={{ float: 'right' }}>

														<p>{userData.phone_number ?? ""}</p>

													</Col>

													<Col md={5}>

														<b>Phone :</b>

													</Col>

												</Col>

												<Col md={12}>

													<Col md={7} style={{ float: 'right' }}>

														<p>{userData.address_1 ?? ""}</p>

													</Col>

													<Col md={5}>

														<b>Address 1 :</b>

													</Col>

												</Col>

												<Col md={12}>

													<Col md={7} style={{ float: 'right' }}>

														<p>{userData.address_2 ?? ""}</p>

													</Col>

													<Col md={5}>

														<b>Address 2:</b>

													</Col>

												</Col>

												<Col md={12}>

													<Col md={7} style={{ float: 'right' }}>

														<p>{userData.city ?? ""}</p>

													</Col>

													<Col md={5}>

														<b>City :</b>

													</Col>

												</Col>
												<Col md={12}>

													<Col md={7} style={{ float: 'right' }}>

														<p>{userData.post_code ?? ""}</p>

													</Col>

													<Col md={5}>

														<b>Post Code :</b>

													</Col>

												</Col>

												<Col md={12}>

													<Col md={7} style={{ float: 'right' }}>

														<p>{userData.country ?? ""}</p>

													</Col>

													<Col md={5}>

														<b>Country :</b>

													</Col>

												</Col>
												<Col md={12} className="mb-3">

													<Col md={7} style={{ float: 'right' }}>

														{(userData.terms_conditions != 0) ? <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked disabled></input> : <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" disabled></input>}

													</Col>

													<Col md={5}>

														<b>Terms & Conditions :</b>

													</Col>

												</Col>
												<Col md={12} className="mb-3">

													<Col md={7} style={{ float: 'right' }}>
														{userData.invitation_status == 1 ? 'Done' : 'Not Done'}
													</Col>

													<Col md={5}>

														<b>Invitation Status :</b>

													</Col>

												</Col>

												<Col md={12} >
													<Col md={7} style={{ float: 'right' }}>
														<p>{(userData.role_id == 1) ? "Admin" : "Member"}</p>
													</Col>

													<Col md={5}>
														<b>Role :</b>
													</Col>
												</Col>

												<Col md={12}>
													<Col md={7} style={{ float: 'right' }}>
														<p>M-000{userData.id ?? ""}</p>
													</Col>

													<Col md={5}>
														<b>Member Id :</b>
													</Col>
												</Col>

											</Row>

										</Card.Body>

									</Card>

								</Col>

							</Row>

						</Modal.Body>

					</Modal>

				</React.Fragment>



				<React.Fragment>
					<Modal as={Modal.Dialog} centered show={showDefault} onHide={handleClose}>
						<Modal.Header>
							<Modal.Title className="h6">Edit User</Modal.Title>
							<Button variant="close" aria-label="Close" onClick={handleClose} />
						</Modal.Header>
						<Modal.Body>
							<Row>
								{error &&
									<Alert variant="danger">
										{error}
									</Alert>
								}
								<Col xs={12} xl={12}>
									<Card border="light" className="bg-white shadow-sm mb-4">
										<Card.Body>
											<h5 className="mb-4">Edit User</h5>
											<Form onSubmit={handleSubmit}>
												<Row>
													<Col md={6} className="mb-3">
														<Form.Group id="firstName">
															<Form.Label>First Name <span class="error-message">*</span></Form.Label>
															<Form.Control type="text" name="first_name" placeholder="Enter your first name" value={formData1.first_name} onChange={handleInputChange} />
														</Form.Group>
														<div className="error-message">{errors.first_name}</div>
													</Col>
													<Col md={6} className="mb-3">
														<Form.Group id="lastName">
															<Form.Label>Last Name</Form.Label>
															<Form.Control type="text" name="last_name" placeholder="Enter last name" value={formData1.last_name} onChange={handleInputChange} />
														</Form.Group>
														<div className="error-message">{errors.last_name}</div>
													</Col>
												</Row>
												<Row>
													<Col md={6} className="mb-3">
														<Form.Group id="emal">
															<Form.Label>Email <span class="error-message">*</span></Form.Label>
															<Form.Control type="email" name="email" placeholder="name@company.com" value={formData1.email} onChange={handleInputChange} readOnly />
														</Form.Group>
														<div className="error-message">{errors.email}</div>
													</Col>
													<Col md={6} className="mb-3">
														<Form.Group id="phone">
															<Form.Label>Phone <span class="error-message">*</span></Form.Label>
															<Form.Control type="text" name="phone_number" id="phone_number" value={formData1.phone_number} placeholder="+44 9999 123456" onChange={handleInputChange} />
														</Form.Group>
														<div className="error-message">{errors.phone_number}</div>
													</Col>
												</Row>
												<Row>
													<Col md={12} className="mb-3">
														<Form.Group id="address_1">
															<Form.Label>Address Line 1</Form.Label>
															<Form.Control type="text" name="address_1" placeholder="Address Line 1" value={formData1.address_1} onChange={handleInputChange} />
														</Form.Group>
														<div className="error-message">{errors.address_1}</div>
													</Col>
												</Row>
												<Row>
													<Col md={12} className="mb-3">
														<Form.Group id="address_2">
															<Form.Label>Address Line 2</Form.Label>
															<Form.Control type="text" name="address_2" placeholder="Address Line 2" value={formData1.address_2} onChange={handleInputChange} />
														</Form.Group>
														<div className="error-message">{errors.address_2}</div>
													</Col>
												</Row>
												<Row>
													<Col md={6} className="mb-3">
														<Form.Group id="city">
															<Form.Label>City</Form.Label>
															<Form.Control type="text" name="city" placeholder="City" value={formData1.city} onChange={handleInputChange} />
														</Form.Group>
														<div className="error-message">{errors.city}</div>
													</Col>
													<Col md={6} className="mb-3">
														<Form.Group id="post_code">
															<Form.Label>Post Code</Form.Label>
															<Form.Control type="text" name="post_code" placeholder="Post Code" value={formData1.post_code} onChange={handleInputChange} />
														</Form.Group>
														<div className="error-message">{errors.post_code}</div>
													</Col>
												</Row>
												<Row>
													<Col md={12} className="mb-3">
														<Form.Group id="country">
															<Form.Label>Country</Form.Label>
															<Form.Select defaultValue="UNITED KINGDOM" name="country" onChange={handleInputChange}>
																<option value="">Choose Country</option>
																<option key="UNITED KINGDOM" value="UNITED KINGDOM">
																	UNITED KINGDOM
																</option>
															</Form.Select>
														</Form.Group>
														<div className="error-message">{errors.country}</div>
													</Col>
												</Row>
												{role_idd === 1 && (
													<Row>
														<Col md={12} className="mb-3">
															<Form.Group controlId="role_id">
																<Form.Label>Select Role:</Form.Label>
																<Form.Select name="role_id" defaultValue={formData1.role_id} onChange={handleInputChange}>
																	<option value="0" selected={formData1.role_id == 0}>Member</option>
																	{setup_admin_permission === "1" && (
																		<option value="1" selected={formData1.role_id == 1}>Admin</option>
																	)}
																</Form.Select>
															</Form.Group>
														</Col>
													</Row>
												)}
												{role_idd === 1 && formData1.role_id == '1' && (
													<Row>
														<h5>Permission</h5>
														<Col md={12} className="mb-3">
															<Form.Group>
																<div className="d-flex justify-content-between align-items-center mb-1">
																	<Form.Check type="checkbox">
																		<FormCheck.Input id="setupAdmin" onChange={handleCheckboxChange} name="setupAdmin" className="me-2" checked={formData1.setupAdmin === "1"} /><span>Setup Admin</span>
																	</Form.Check>
																</div>
																<div className="error-message"></div>
															</Form.Group>
														</Col>
														<Col md={12} className="mb-3">
															<Form.Group>
																<div className="d-flex justify-content-between align-items-center mb-1">
																	<Form.Check type="checkbox">
																		<FormCheck.Input id="inviteUser" onChange={handleCheckboxChange} name="inviteUser" className="me-2" checked={formData1.inviteUser === "1"} /><span>Invite User</span>
																	</Form.Check>
																</div>
																<div className="error-message"></div>
															</Form.Group>
														</Col>
														<Col md={12} className="mb-3">
															<Form.Group>
																<div className="d-flex justify-content-between align-items-center mb-1">
																	<Form.Check type="checkbox">
																		<FormCheck.Input id="assignUnavailableMonth" onChange={handleCheckboxChange} name="assignUnavailableMonth" className="me-2" checked={formData1.assignUnavailableMonth === "1"} /><span>Assign Unavailable month to users</span>
																	</Form.Check>
																</div>
																<div className="error-message"></div>
															</Form.Group>
														</Col>
														<Col md={12} className="mb-3">
															<Form.Group>
																<div className="d-flex justify-content-between align-items-center mb-1">
																	<Form.Check type="checkbox">
																		<FormCheck.Input id="receiveMonthApproval" onChange={handleCheckboxChange} name="receiveMonthApproval" className="me-2" checked={formData1.receiveMonthApproval === "1"} /><span>Receive Unavailable Month Approval Requests</span>
																	</Form.Check>
																</div>
																<div className="error-message"></div>
															</Form.Group>
														</Col>
														<Col md={12} className="mb-3">
															<Form.Group>
																<div className="d-flex justify-content-between align-items-center mb-1">
																	<Form.Check type="checkbox">
																		<FormCheck.Input id="suspendUser" onChange={handleCheckboxChange} name="suspendUser" className="me-2" checked={formData1.suspendUser === "1"} /><span>Suspend User</span>
																	</Form.Check>
																</div>
																<div className="error-message"></div>
															</Form.Group>
														</Col>
														<Col md={12} className="mb-3">
															<Form.Group>
																<div className="d-flex justify-content-between align-items-center mb-1">
																	<Form.Check type="checkbox">
																		<FormCheck.Input id="deleteUser" onChange={handleCheckboxChange} name="deleteUser" className="me-2" checked={formData1.deleteUser === "1"} /><span>Delete User</span>
																	</Form.Check>
																</div>
																<div className="error-message"></div>
															</Form.Group>
														</Col>
													</Row>
												)}
												<div className="mt-3 center">
													<Button variant="primary" data-button-name="save" type="submit" style={btnWidth}>Save</Button>&nbsp;&nbsp;
													<Button variant="primary" onClick={handleClose} style={btnWidth}>
														Cancel
													</Button>
													&nbsp;
												</div>
											</Form>
										</Card.Body>
									</Card>
								</Col>
							</Row>
						</Modal.Body>
					</Modal>
				</React.Fragment>



			</Card>


		</>

	);

};